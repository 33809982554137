// import { useState, useEffect, useContext } from 'react'
import { TextAreaInterface } from './TextArea.interface'
import Clarity from '@microsoft/clarity'

const TextArea = ({ name, required, placeholder, value, onChange, testID }: TextAreaInterface) => {
    const changeHandler = (v: string) => {
        onChange(v)
    }

    Clarity.setTag('TextArea', name as string)

    return (
        <textarea
            name={name}
            data-testid={testID}
            placeholder={placeholder}
            required={required}
            onChange={(e) => changeHandler(e.target.value)}
            className={`TextArea`}
            rows={4}
            value={value}
        />
    )
}

export default TextArea
