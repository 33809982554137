import { useContext } from 'react'
import { HomeInterface } from './Home.interface'
import { Col, Row } from 'react-bootstrap'
import { AppContext } from 'src/context'
import { DataType } from 'src/app-types'
import { Parallax, Section } from 'src/components/molecules'
import Clarity from '@microsoft/clarity'

const Home = ({ testID }: HomeInterface) => {
    const ctx = useContext(AppContext)

    const data: DataType[] | undefined = ctx?.data?.filter((item) => item.type !== 'header')
    const r: DataType | undefined = ctx?.data?.find((item) => item.type === 'header')
    const hTitle = ctx.language === 'nl' ? r?.title_nl : r?.title_en
    const hContent = ctx.language === 'nl' ? r?.content_nl : r?.content_en

    Clarity.event('Homepage Viewed')

    return (
        <div id='home' data-testid={testID} className={`Home`}>
            <Parallax title={hTitle ?? ''} content={hContent ?? ''} item={r} language={ctx.language ?? 'en'} />
            <Row>
                <Col md={2} />
                <Col md={8}>
                    {data?.map((item: DataType, index: number) => {
                        return (
                            <Section
                                key={index}
                                item={item}
                                language={ctx.language ?? 'en'}
                                imgPosition={index % 2 === 0 ? 'left' : 'right'}
                            />
                        )
                    })}
                </Col>
                <Col md={2} />
            </Row>
        </div>
    )
}

export default Home
