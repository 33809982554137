// import { useState, useEffect, useContext } from 'react'
import { ButtonInterface } from './Button.interface'
import { colors } from 'src/app-types'
import Clarity from '@microsoft/clarity'

const Button = ({ testID, text, action, type = colors.blue }: ButtonInterface) => {
    const handleClick = (): void => {
        action && action()
    }

    Clarity.setTag('Button', text as string)

    return (
        <div onClick={() => handleClick()} data-testid={testID} data-object-type={type ?? ''} className={`Button`}>
            {text}
        </div>
    )
}

export default Button
